const safeParse = (text)=> {
  try {
    return JSON.parse(text);
  } catch (err) {
    console.error(err);
    return {};
  }
}

$(document).on('turbo:load', (e)=> {
  const userDataElement = document.getElementById("user-data-tag");
  const userData = safeParse(userDataElement?.textContent);

  /* HubSpot - Page View */
  if (window._hsq) {
    _hsq.push(['setPath', window.location.pathname]);
    _hsq.push(['trackPageView']);
  }

  /* Google Analytics - Page View */
  if (window.gtag) {
    if (userData.id) {
      gtag('config', window.gaAccountCode, {
        'user_id': userData.id
      });
    } else {
      gtag('config', window.gaAccountCode);
    }
  }

  /* Google Analytics - Event */
  const googleAnalyticsEvent = document.getElementById("google-analytics-event");
  if (window.gtag && googleAnalyticsEvent) {
    const eventData = safeParse(googleAnalyticsEvent.textContent);
    gtag('event', eventData.action, eventData.params);
  }

  /* Google Ads - Page View */
  if (window.gtag) {
    gtag('config', window.googleAdsAccount, { 'allow_enhanced_conversions': true });
  }

  /* Google Ads - Conversion */
  const googleAdsConversionEvent = document.getElementById("google-ads-conversion-event");
  if (window.gtag && googleAdsConversionEvent) {
    const eventData = safeParse(googleAdsConversionEvent.textContent);

    window.enhanced_conversion_data = {
      email: userData.email
    }

    gtag('set', 'user_data', {
      'email': userData.email
    });

    gtag('event', eventData.name, {
      'user_id': userData.id,
      'send_to': eventData.target,
      ...eventData.extra,
    });
  }

  /* LinkedIn - Page View */
  if (window.lintrk && !window._already_called_lintrk) {
    lintrk('track');
    window._already_called_lintrk = true;
  }

  /* Attribution - Page View */
  if (window.Attribution) {
    if (e.originalEvent.detail.timing.visitStart) {
      Attribution.page({ referrer: Turbo.navigator.currentVisit.referrer.href });
    } else {
      Attribution.page();
    }
  }

  /* Attribution - Event */
  const attributionAnalyticsEvent = document.getElementById("attribution-analytics-event");
  if (window.Attribution && attributionAnalyticsEvent) {
    const eventData = safeParse(attributionAnalyticsEvent.textContent);

    if (eventData.event === "sign_in") {
      Attribution.identify(userData.id.toString(), {
        name: userData.name,
        email: userData.email
      });
    }

    if (eventData.event === "sign_up") {
      Attribution.identify(userData.id.toString(), {
        name: userData.name,
        email: userData.email,
        createdAt: new Date() // Note: Only do this once!!!
      });

      Attribution.track("Signed Up");
    }
  }
});

$(document).on("turbo:before-visit", function() {
  /* LinkedIn - Reset tracker state */
  if (window.lintrk) {
    window._already_called_lintrk = false;
  }
});
